.btbox[data-v-0465a4b6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin-top: 15px;
}
.dialog-footer1[data-v-0465a4b6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
[data-v-0465a4b6] .avue-crud .el-table {
  height: calc(100vh - 218px) !important;
  max-height: calc(100vh - 218px) !important;
}
